export const voucherMessages = {
  KRDRNPK: 'KRDRNPK',
  INNOKLICK24: 'INNOKLICK24',
};

export const rentalRequestSources = {
  klickrentOnlineRequest: 'klickrent Online Request',
  email: 'Email',
  phone: 'Phone request',
};

export const TRANSPORT_REQUESTED = 'zzgl. Transport zum Einsatzort';

export const TRANSPORT_NOT_REQUESTED = 'kein Transport, Selbstabholung';

export const TRANSPORT_FEE_PRODUCT_ID = '01t08000009uTRSAA2';

export const OPPORTUNITY_LINE_ITEM_STATUS = {
  acceptedByOwner: 'AcceptedByOwner',
  declinedByOwner: 'DeclinedByOwner',
};

export const OPPORTUNITY_STAGES = {
  created: 'Erstellt',
  requestSent: 'Anfrage versendet',
  offerReceived: 'Angebot erhalten',
  acceptedByOwner: 'Von Vermieter angenommen',
  closedWon: 'Closed Won',
  closedLost: 'Closed Lost',
  acceptedByKlickrent: 'Von klickrent angenommen',
  delivered: 'Ausgeliefert',
  damaged: 'Schaden',
};

export const HOURS = ['stunde', 'hour'];

export const AVERAGE_RATING = '4.7';
